import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { InputField, InputFieldProps } from "../inputField/inputField";
import type { DataTableProps } from "./DataTable";

export function Search<T>({
    table,
    ...props
}: Pick<DataTableProps<T>, "table"> & Omit<InputFieldProps, "value" | "onChange" | "ref">): JSX.Element {
    const tableState = table.getState();

    return (
        <InputField
            {...props}
            value={tableState.globalFilter || ""}
            onChange={(e) => table.setGlobalFilter(e.target.value)}
            startAdornment={<MagnifyingGlassIcon className="h-4 w-4 shrink-0 text-black/40" />}
        />
    );
}
