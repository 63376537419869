import { StripeUtils } from "@deathstar/reuse";
import { PayBillFormData } from "@deathstar/types/waypoint";
import { classNames, InputField, Popover, PrimaryButton, SecondaryButton } from "@deathstar/ui";
import { BuildingLibraryIcon, ChevronDownIcon, CreditCardIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import { capitalize } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { Dialog } from "../../components/dialog/Dialog";
import { queryClient } from "../../util/queryClient";
import { toUSD } from "../../util/toUSD";
import NewPaymentMethodForm from "./NewPaymentMethodForm";

export default function PaymentDialog({
    open,
    onClose,
    defaultValues,
}: {
    open: boolean;
    defaultValues?: Partial<PayBillFormData>;
    onClose(data?: PayBillFormData): void;
}) {
    const accountId = useAccountId();
    const [wireTransferOpen, setWireTransferOpen] = useState(false);
    const [creating, setCreating] = useState(false);
    const loaded = useRef(false);
    const { data, refetch } = api.billing.useProfile(accountId!);

    const form = useForm<PayBillFormData>({
        defaultValues: {
            paymentMethod: data?.paymentMethods.find((pm) => pm.isDefault)?.id || "",
            invoiceNumber: "",
            description: "",
            ...defaultValues,
        },
    });

    useEffect(() => {
        if (!loaded.current && data) {
            loaded.current = true;
            form.setValue("paymentMethod", data.paymentMethods.find((pm) => pm.isDefault)?.id || "");
        }
    }, [data, form]);

    const { mutate, isPending, error } = api.billing.useCreateAndPayInvoice(accountId!, {
        onSuccess: (_data, vars) => {
            onClose(vars);
            queryClient.invalidateQueries({ queryKey: api.billing.queryKeys.paymentHistory(accountId!) });
        },
    });

    const paymentMethod = data?.paymentMethods.find((pm) => pm.id === form.watch("paymentMethod"));

    const amount = form.watch("amount") || 0;
    const fee = paymentMethod
        ? StripeUtils.fees.shouldIncludeFeeInTotal(paymentMethod.type as "card" | "us_bank_account")
            ? amount > 100_000
                ? 0
                : StripeUtils.fees.getFeeAmount(
                      StripeUtils.formatting.dollarsToCents(amount),
                      paymentMethod.type as "card" | "us_bank_account"
                  )
            : 0
        : 0;

    if (!data) {
        return null;
    }

    return (
        <>
            <Dialog open={open && !creating && !wireTransferOpen} onClose={() => onClose()} className="w-screen flex-col p-4 md:max-w-lg">
                <form
                    onSubmit={form.handleSubmit(async (data) => {
                        if (isPending) return;
                        if (paymentMethod?.type === "card") return;
                        mutate(data);
                    })}
                    className="mb-2 space-y-4"
                >
                    <Popover>
                        <Popover.Button className="flex w-full items-center justify-between rounded-lg border border-stone-300 px-3 py-2 text-sm">
                            <span>{capitalize(paymentMethod?.description || "Payment method")}</span>
                            <ChevronDownIcon className="h-4 w-4" />
                        </Popover.Button>
                        <Popover.Content
                            style={{
                                // --button-width is provided by headlessui
                                width: "var(--button-width)",
                            }}
                        >
                            {data?.paymentMethods.map((p) => (
                                <Popover.Item
                                    key={p.id}
                                    onClick={() => {
                                        form.setValue("paymentMethod", p.id);
                                    }}
                                    className={classNames(
                                        "w-full rounded",
                                        p.id === paymentMethod?.id ? "bg-waypoint-blue text-white hover:!bg-waypoint-blue" : ""
                                    )}
                                >
                                    {p.type === "card" ? (
                                        <CreditCardIcon className="h-4 w-4" />
                                    ) : p.type === "us_bank_account" ? (
                                        <BuildingLibraryIcon className="h-4 w-4" />
                                    ) : (
                                        <div className="h-4 w-4"></div>
                                    )}
                                    {capitalize(p.description)}
                                </Popover.Item>
                            ))}
                            <hr className="my-1" />
                            <Popover.Item
                                onClick={() => {
                                    setCreating(true);
                                }}
                                className="!text-blue-600"
                            >
                                New payment method
                            </Popover.Item>
                        </Popover.Content>
                    </Popover>
                    <InputField {...form.register("description")} placeholder="Description" />
                    <div className="flex gap-2">
                        <InputField {...form.register("invoiceNumber")} placeholder="Invoice # (optional)" className="w-1/2" />
                        <InputField
                            type="number"
                            startAdornment="$"
                            {...form.register("amount", {
                                valueAsNumber: true,
                                validate: (value) => {
                                    if (!value) {
                                        return "An amount is required";
                                    }
                                    if (paymentMethod?.type === "card" && value > 100_000) {
                                        return "The maximum amount for credit card payments is $100,000. Please select a different payment method or reduce the amount.";
                                    }
                                    if (value < 1) {
                                        return "Amount cannot be < $1";
                                    }
                                },
                            })}
                            step={0.01}
                            min={0}
                            className="w-1/2"
                            placeholder="Amount"
                        />
                    </div>
                    {paymentMethod?.type === "card" && !!amount && (
                        <p className="!mt-2 text-sm text-stone-500">
                            A <span className="font-medium">{StripeUtils.formatting.centsToDollarString(fee)}</span> convenience fee will be
                            added to your payment for credit card processing. See our{" "}
                            <a
                                href="https://help.navigatortruckinsurance.com/hc/en-us/articles/28440930023579-Convenience-Fee-Schedule-for-Credit-Card-Payments-for-Insurance-Premiums"
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-600"
                            >
                                fee schedule
                            </a>{" "}
                            for more information.
                        </p>
                    )}
                    {!!amount && (
                        <p className="!mt-8 text-sm text-stone-500">
                            By submitting this form you authorize Navigator Truck Insurance to immediately debit the selected payment method
                            for <span className="font-medium text-black">{toUSD(amount + fee / 100)}</span>
                        </p>
                    )}
                    <div className="flex justify-end">
                        <PrimaryButton className="w-48" disabled={isPending || !paymentMethod || !amount}>
                            {isPending ? <CircularProgress size="1rem" className="" classes={{ svg: "text-white" }} /> : "Confirm payment"}
                        </PrimaryButton>
                    </div>
                    {error ? (
                        <p className="text-sm text-red-600">
                            {capitalize((error as Error).message || "Your payment failed. Please try again later.")}
                        </p>
                    ) : null}
                    <div className={classNames("text-sm text-red-600", Object.values(form.formState.errors).length ? "" : "hidden")}>
                        <p>
                            {Object.values(form.formState.errors)
                                .map((error) => error.message)
                                .join("; ")}
                        </p>
                    </div>
                </form>
                <button
                    onClick={() => {
                        setWireTransferOpen(true);
                        onClose();
                    }}
                    className="text-left text-sm font-medium text-blue-600"
                >
                    Paying by wire transfer?
                </button>
            </Dialog>

            <Dialog
                open={creating && !wireTransferOpen}
                onClose={() => {
                    setCreating(false);
                }}
                className="overflow-y-auto p-4 pb-0"
            >
                <NewPaymentMethodForm
                    onSubmit={(setupIntent) => {
                        setCreating(false);
                        if (setupIntent) {
                            refetch().then(() => {
                                form.setValue("paymentMethod", setupIntent.payment_method as string);
                            });
                        }
                    }}
                />
            </Dialog>

            <Dialog open={wireTransferOpen} onClose={() => setWireTransferOpen(false)} className="max-w-prose text-stone-800">
                <div className="space-y-8 p-4">
                    <p>
                        There is no fee for Navigator Truck Insurance Agency to receive a wire transfer. Most banks charge a fee to send a
                        wire transfer; obtain fee information by contacting your bank. You will need to provide your bank with the following
                        information:
                    </p>
                    <ul className="list-inside list-disc">
                        <li>Name as shown at bank: NAVIGATOR TRUCK INSURANCE AGENCY, INC.</li>
                        <li>Physical address of Navigator Truck Insurance Agency, Inc: 529 Baldwin Street, Jenison, MI 49428</li>
                        <li>Phone number of Navigator Truck Insurance Agency, Inc: 616-457-7100</li>
                        <li>Wire will be received at: FIFTH THIRD BANK</li>
                        <li>Physical address of Fifth Third Bank: 530 Baldwin Street, Jenison, MI 49428</li>
                        <li>Phone number of Fifth Third Bank: 616-457-3350</li>
                        <li>
                            Routing number for wire transfers: <span className="font-mono">042000314</span>
                        </li>
                        <li>
                            Account number for wire transfers: <span className="font-mono">7927353784</span>
                        </li>
                    </ul>
                    <div className="flex justify-end">
                        <SecondaryButton onClick={() => setWireTransferOpen(false)}>Close</SecondaryButton>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
