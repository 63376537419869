import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Account } from "@deathstar/types/salesforce";
import { User } from "@deathstar/types/waypoint";
import { ErrorBoundary } from "@sentry/react";
import { useEffect } from "react";
import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import api from "../../api/api";
import { AccountIdProvider } from "../../api/useAccountId";
import { ConnectionError, ServerUnavailableError, UnauthorizedError } from "../../api/util/exceptions";
import AccountNotFound from "../../components/error-screens/AccountNotFound";
import NoInternetError from "../../components/error-screens/NoInternetError";
import ServerDownError from "../../components/error-screens/ServerDownError";
import SolPageNotFound from "../../components/error-screens/SolPageNotFound";
import UncaughtException from "../../components/error-screens/UncaughtException";
import Advice from "../../features/advice/Advice";
import Billing from "../../features/billing/Billing";
import Certificates from "../../features/certificates/Certificates";
import CertificateView from "../../features/certificates/CertificateView";
import Dashboard from "../../features/dashboard/Dashboard";
import Drivers from "../../features/drivers/Drivers";
import Equipment from "../../features/equipment/Equipment";
import MonthlyReportSelector from "../../features/equipment/monthly-reports/MonthlyReportSelector";
import { InspectionAnalysis } from "../../features/inspection-analysis/InspectionAnalysis";
import { Navigation } from "../../features/navigation/Navigation";
import OwnerOperators from "../../features/owner-operators/OwnerOperators";
import Policies from "../../features/policies/Policies";
import MemberPermissions from "../../features/settings/members/MemberPermissions";
import Settings from "../../features/settings/Settings";
import { queryClient } from "../../util/queryClient";
import Resources from "../resources/Resources";
import SplashScreen from "./SplashScreen";

const Sol = withAuthenticationRequired(
    function () {
        const params = useParams();
        const { data: user, isLoading: isLoadingUser, isError, error } = api.user.useMe();
        const { isLoading: isLoadingOrganization, error: organizationError } = api.organization.useById(params.accountId!, {
            enabled: !!params.accountId,
        });

        const { data: organizationType } = api.organization.useType(params.accountId!, { enabled: !!params.accountId });

        useEffect(() => {
            if (
                params.accountId &&
                user?.lastVisitedAccountId !== params.accountId &&
                user?.memberships?.find((m) => m.accountId === params.accountId)
            ) {
                api.user
                    .update({ lastVisitedAccountId: params.accountId })
                    .then(() => {
                        queryClient.setQueryData<User>(api.user.queryKeys.me(), (old) => {
                            if (old) {
                                return {
                                    ...old,
                                    lastVisitedAccountId: params.accountId as string,
                                };
                            } else {
                                // this is actually undefined but react-query's types don't like that for some reason
                                return old as unknown as User;
                            }
                        });
                    })
                    .catch((e) => {
                        console.error("Could not save lastVisitedAccountId", e);
                    });
            }
        }, [params.accountId, user]);

        if (isLoadingUser || isLoadingOrganization) {
            return <SplashScreen />;
        }
        if (isError) {
            if (error instanceof ConnectionError) {
                return <NoInternetError />;
            }
            if (error instanceof ServerUnavailableError) {
                return <ServerDownError />;
            }
            return <UncaughtException />;
        }
        if (!user) {
            return <Navigate to="/member-setup" />;
        }
        if (!params.accountId && !user.memberships?.length) {
            return <Navigate to="/member-setup" />;
        }
        if (!params.accountId && user.memberships?.length) {
            if (user.lastVisitedAccountId && user.memberships.find((m) => m.accountId === user.lastVisitedAccountId)) {
                return <Navigate to={`/sol/${user.lastVisitedAccountId}`} />;
            } else {
                return <Navigate to={`/sol/${user.memberships[0].accountId}`} />;
            }
        }
        if (organizationError) {
            if (organizationError instanceof UnauthorizedError) {
                return <AccountNotFound />;
            }
            return <UncaughtException />;
        }

        return (
            <AccountIdProvider accountId={params.accountId!}>
                <Routes>
                    <Route
                        element={
                            <div className="flex h-screen flex-col lg:flex-row">
                                <Navigation
                                    routes={
                                        organizationType?.type === Account.RecordTypes.IndpendentContractor
                                            ? [
                                                  "home",
                                                  "policies",
                                                  "certificates",
                                                  "drivers",
                                                  "equipment",
                                                  "fmcsa-insights",
                                                  "advice",
                                                  "resources",
                                                  "billing",
                                              ]
                                            : undefined
                                    }
                                />
                                <ErrorBoundary fallback={<UncaughtException />}>
                                    <div className="flex h-full grow flex-col overflow-y-auto">
                                        {user.email.endsWith("@navigatortruckinsurance.com") &&
                                            !user.memberships?.find((m) => m.accountId === params.accountId) && (
                                                <p className="bg-waypoint-orange px-4 py-0.5 text-center text-sm text-black">
                                                    You have readonly access to this organization
                                                </p>
                                            )}
                                        <div className="flex grow flex-col overflow-y-auto">
                                            <Outlet />
                                        </div>
                                    </div>
                                </ErrorBoundary>
                            </div>
                        }
                    >
                        <Route index element={<Dashboard />} />
                        <Route path="drivers/*" element={<Drivers />} />
                        <Route path="equipment/*" element={<Equipment />} />
                        <Route path="policies/*" element={<Policies />} />
                        <Route
                            path="certificates/*"
                            element={
                                <Routes>
                                    <Route index element={<Certificates />} />
                                    <Route path="/:certificateId" element={<CertificateView />} />
                                    <Route path="*" element={<SolPageNotFound />} />
                                </Routes>
                            }
                        />
                        <Route path="advice/*" element={<Advice />} />
                        <Route path="members" element={<MemberPermissions />} />
                        <Route path="billing/*" element={<Billing />} />
                        <Route path="settings/*" element={<Settings />} />
                        <Route path="resources" element={<Resources />} />
                        <Route path="fmcsa-insights" element={<InspectionAnalysis />} />
                        <Route path="owner-operators/*" element={<OwnerOperators />} />
                        <Route path="monthly-reports/:policyId?/:year?/:month?/:step?" element={<MonthlyReportSelector />} />
                        <Route path="*" element={<SolPageNotFound />} />
                    </Route>
                </Routes>
            </AccountIdProvider>
        );
    },
    { returnTo: () => window.location.pathname }
);

export default Sol;
