import { BusinessAuto, EquipmentType, InlandMarineEquipment as Tool, Tractor, Trailer } from "@deathstar/types/northstar";
import { BusinessAutoDto, EquipmentDriverChangeDto, EquipmentRow, ToolDto, TractorDto, TrailerDto } from "@deathstar/types/waypoint";
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { queryClient } from "../../util/queryClient";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";
import { monthlyReports } from "./monthlyReports";

export type EquipmentTypeString = "tractors" | "trailers" | "autos" | "tools";

export const equipment = {
    queryKeys: {
        find: (type: EquipmentTypeString, accountId: string) => [accountId, type] as QueryKey,
        findTypes: () => ["equipment-types"] as QueryKey,
    },

    findTypes: (accountId: string) => {
        return blazar.fetchJson<EquipmentType[]>(`/waypoint/orgs/${accountId}/equipment-types`);
    },

    useFindTypes: <T = EquipmentType[]>(accountId: string, config?: Partial<UseQueryOptions<EquipmentType[], ResponseError, T>>) =>
        useQuery<EquipmentType[], ResponseError, T>({
            queryKey: equipment.queryKeys.findTypes(),
            queryFn: () => blazar.fetchJson(`waypoint/orgs/${accountId}/equipment-types`),
            enabled: !!accountId,
            retry: (count, error) => {
                return count < 3 && error.status !== 403 && error.status !== 429;
            },
            ...config,
        }),

    find: <T extends Tractor | Trailer | BusinessAuto | Tool>(type: EquipmentTypeString, accountId: string) => {
        return blazar.fetchJson<EquipmentRow<T>[]>(`/waypoint/orgs/${accountId}/equipment/${type}`);
    },

    useFind: <Type extends Tractor | Trailer | BusinessAuto | Tool, T = EquipmentRow<Type>[]>(
        type: EquipmentTypeString,
        accountId: string,
        config?: Partial<UseQueryOptions<EquipmentRow<Type>[], ResponseError, T>>
    ) =>
        useQuery<EquipmentRow<Type>[], ResponseError, T>({
            queryKey: equipment.queryKeys.find(type, accountId),
            queryFn: () => blazar.fetchJson(`waypoint/orgs/${accountId}/equipment/${type}`),
            enabled: !!accountId,
            retry: (count, error) => {
                return count < 3 && error.status !== 403 && error.status !== 429;
            },
            ...config,
        }),

    findTractors: (accountId: string) => {
        return equipment.find<Tractor>("tractors", accountId);
    },

    useFindTractors: <T = EquipmentRow<Tractor>[]>(
        accountId: string,
        config?: Partial<UseQueryOptions<EquipmentRow<Tractor>[], ResponseError, T>>
    ) => {
        return equipment.useFind<Tractor, T>("tractors", accountId, config);
    },

    findTrailers: (accountId: string) => {
        return equipment.find<Trailer>("trailers", accountId);
    },

    useFindTrailers: <T = EquipmentRow<Trailer>[]>(
        accountId: string,
        config?: Partial<UseQueryOptions<EquipmentRow<Trailer>[], ResponseError, T>>
    ) => {
        return equipment.useFind<Trailer, T>("trailers", accountId, config);
    },

    findAutos: (accountId: string) => {
        return equipment.find<BusinessAuto>("autos", accountId);
    },

    useFindAutos: <T = EquipmentRow<BusinessAuto>[]>(
        accountId: string,
        config?: Partial<UseQueryOptions<EquipmentRow<BusinessAuto>[], ResponseError, T>>
    ) => {
        return equipment.useFind<BusinessAuto, T>("autos", accountId, config);
    },

    findTools: (accountId: string) => {
        return equipment.find<Tool>("tools", accountId);
    },

    useFindTools: <T = EquipmentRow<Tool>[]>(
        accountId: string,
        config?: Partial<UseQueryOptions<EquipmentRow<Tool>[], ResponseError, T>>
    ) => {
        return equipment.useFind<Tool, T>("tools", accountId, config);
    },

    create: async <Dto extends TractorDto | TrailerDto | BusinessAutoDto | ToolDto>(
        type: EquipmentTypeString,
        accountId: string,
        body: Dto
    ) => {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/equipment/${type}`, {
            method: "POST",
            body: JSON.stringify(body),
        });
    },

    useCreate: <Dto extends TractorDto | TrailerDto | BusinessAutoDto | ToolDto>(
        type: EquipmentTypeString,
        accountId: string,
        config?: UseMutationOptions<void, ResponseError, Dto>
    ) => {
        return useMutation<void, ResponseError, Dto>({
            mutationFn: (data) => {
                return equipment.create<Dto>(type, accountId, data);
            },
            ...config,
            onSuccess: (...args) => {
                queryClient.invalidateQueries({ queryKey: equipment.queryKeys.find(type, accountId) });
                queryClient.invalidateQueries({ queryKey: monthlyReports.queryKeys.find(accountId) });
                config?.onSuccess?.(...args);
            },
        });
    },

    createTractor: async (accountId: string, body: TractorDto) => {
        return await equipment.create<TractorDto>("tractors", accountId, body);
    },

    useCreateTractor: (accountId: string, config?: UseMutationOptions<void, ResponseError, TractorDto>) => {
        return equipment.useCreate<TractorDto>("tractors", accountId, config);
    },

    createTrailer: async (accountId: string, body: TrailerDto) => {
        return await equipment.create<TrailerDto>("trailers", accountId, body);
    },

    useCreateTrailer: (accountId: string, config?: UseMutationOptions<void, ResponseError, TrailerDto>) => {
        return equipment.useCreate<TrailerDto>("trailers", accountId, config);
    },

    createBusinessAuto: async (accountId: string, body: BusinessAutoDto) => {
        return await equipment.create<BusinessAutoDto>("autos", accountId, body);
    },

    useCreateBusinessAuto: (accountId: string, config?: UseMutationOptions<void, ResponseError, BusinessAutoDto>) => {
        return equipment.useCreate<BusinessAutoDto>("autos", accountId, config);
    },

    createTool: async (accountId: string, body: ToolDto) => {
        return await equipment.create<ToolDto>("tools", accountId, body);
    },

    useCreateTool: (accountId: string, config?: UseMutationOptions<void, ResponseError, ToolDto>) => {
        return equipment.useCreate<ToolDto>("tools", accountId, config);
    },

    getAutoId: async (type: Exclude<EquipmentTypeString, "tools">, accountId: string, unitId: number) => {
        const id = type === "tractors" ? { tractorId: unitId } : type === "trailers" ? { trailerId: unitId } : { businessAutoId: unitId };
        const response = await blazar.fetch(`waypoint/orgs/${accountId}/auto-id/unit`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(id),
        });

        return await response.blob();
    },

    useGetAutoId: (
        type: Exclude<EquipmentTypeString, "tools">,
        accountId: string,
        config?: UseMutationOptions<Blob, ResponseError, number>
    ) => {
        return useMutation<Blob, ResponseError, number>({
            mutationFn: (data) => {
                return equipment.getAutoId(type, accountId, data);
            },
            ...config,
        });
    },

    requestRemoval: async (type: EquipmentTypeString, accountId: string, id: number, requestDate: string) => {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/equipment/${type}/${id}?requestDate=${requestDate}`, {
            method: "DELETE",
        });
    },

    useRequestRemoval: (
        type: EquipmentTypeString,
        accountId: string,
        config?: UseMutationOptions<void, ResponseError, { id: number; requestDate: string }>
    ) => {
        return useMutation({
            mutationFn: ({ id, requestDate }) => {
                return equipment.requestRemoval(type, accountId, id, requestDate);
            },
            ...config,
            onSuccess: (...args) => {
                queryClient.invalidateQueries({ queryKey: equipment.queryKeys.find(type, accountId) });
                queryClient.invalidateQueries({ queryKey: monthlyReports.queryKeys.find(accountId) });
                config?.onSuccess?.(...args);
            },
        });
    },

    requestChange: async (type: EquipmentTypeString, accountId: string, id: number, data: Omit<EquipmentDriverChangeDto, "coverages">) => {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/equipment/${type}/${id}`, {
            method: "PATCH",
            body: JSON.stringify(data),
        });
    },

    useRequestChange: (
        type: EquipmentTypeString,
        accountId: string,
        config?: UseMutationOptions<void, ResponseError, { id: number } & Omit<EquipmentDriverChangeDto, "coverages">>
    ) => {
        return useMutation({
            mutationFn: ({ id, ...data }) => {
                return equipment.requestChange(type, accountId, id, data);
            },
            ...config,
            onSuccess: (...args) => {
                queryClient.invalidateQueries({ queryKey: equipment.queryKeys.find(type, accountId) });
                queryClient.invalidateQueries({ queryKey: monthlyReports.queryKeys.find(accountId) });
                config?.onSuccess?.(...args);
            },
        });
    },
};
