import { CircularProgress, Paper, Typography } from "@material-ui/core";
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    LinearScale,
    LineController,
    LineElement,
    PieController,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import Annotation from "chartjs-plugin-annotation";
import React from "react";

import { MotorCarrier } from "@deathstar/motor-carrier";
import { DateTime } from "@deathstar/reuse";

import { Context } from "./Context";
// import { Icon } from "./Icon";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FmcsaLogoIcon } from "../icons/fmcsa-logo";
import { FmcsaLogoInvertedIcon } from "../icons/fmcsa-logo-inverted";
import { Panel } from "./Components/Panel";
import { Crashes } from "./Crashes/Crashes";
import { Footer } from "./Footer";
import { Insights } from "./Insights/Insights";
import { Inspections } from "./Inspections/Inspections";
import { Navigation } from "./Navigation";
import { Summary } from "./Summary/Summary";
import { Violations } from "./Violations/Violations";

export const Icon = {
    FmcsaLogoIcon,
    FmcsaLogoInvertedIcon,
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    ArcElement,
    PointElement,
    PieController,
    BarController,
    LineController,
    Tooltip,
    Title,
    Annotation
);

function useMotorCarrier({ dot, smsScoreDates, fetcher }: { dot: number; smsScoreDates?: Date[]; fetcher: MotorCarrier.IFetcher }) {
    return useMutation({
        mutationFn: async (): Promise<MotorCarrier> => {
            const latestSnapshotDate = await fetcher.fetchLatestSnapshotDate();
            const snapshotDate = DateTime.fromDate(latestSnapshotDate);
            const mc = await MotorCarrier.new({
                dot,
                snapshotDate: snapshotDate,
                smsScoreDates: smsScoreDates?.map((d) => DateTime.fromDate(d)) ?? [
                    DateTime.subtractMonths(snapshotDate, 11),
                    DateTime.subtractMonths(snapshotDate, 10),
                    DateTime.subtractMonths(snapshotDate, 9),
                    DateTime.subtractMonths(snapshotDate, 8),
                    DateTime.subtractMonths(snapshotDate, 7),
                    DateTime.subtractMonths(snapshotDate, 6),
                    DateTime.subtractMonths(snapshotDate, 5),
                    DateTime.subtractMonths(snapshotDate, 4),
                    DateTime.subtractMonths(snapshotDate, 3),
                    DateTime.subtractMonths(snapshotDate, 2),
                    DateTime.subtractMonths(snapshotDate, 1),
                    snapshotDate,
                ],
                fetcher,
                config: {
                    // forceCalculateBasicMeasures: true,
                },
            });

            return mc;
        },
    });
}

export function WebIA({
    dot,
    smsScoreDates,
    fetcher,
    loadingMessage,
}: {
    dot: number;
    smsScoreDates?: Date[];
    fetcher: MotorCarrier.IFetcher;
    loadingMessage?: string | JSX.Element;
}): JSX.Element {
    const [activeTab, setActiveTab] = React.useState(0);
    const { mutateAsync: fetchMotorCarrier } = useMotorCarrier({ dot, smsScoreDates, fetcher });

    const {
        data: motorCarrier,
        isLoading,
        isError,
        error,
    } = useQuery({
        queryKey: ["inspection-analysis", dot],
        queryFn: () =>
            fetchMotorCarrier().then((mc) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (window as any).mc = mc;
                return mc.filterByDateRange({
                    dateRange: MotorCarrier.calculateDateRange({ snapshotDate: mc.dateRange.to }),
                    keepAllSmsResults: true,
                });
            }),
        refetchInterval: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
    });

    return (
        <div className="flex flex-col gap-4">
            {/* <div className="relative flex w-min items-end gap-2">
                <Icon className="h-20 w-20" />
                <Typography className="relative right-4 text-3xl italic">Web</Typography>
            </div>
            <hr className="my-4" /> */}

            {isLoading && (
                <div className="flex gap-2">
                    <CircularProgress color="inherit" className="text-primary" size="20px" />
                    {typeof loadingMessage === "string" && <Typography>{loadingMessage}</Typography>}
                    {typeof loadingMessage === "function" && loadingMessage}
                    {!loadingMessage && (
                        <Typography>
                            Loading <span className="italic">Web</span>IA... ({dot})
                        </Typography>
                    )}
                </div>
            )}
            {isError && <code>{String(error)}</code>}

            {motorCarrier && !isLoading && (
                <Context.Provider
                    value={{
                        activeTab,
                        setActiveTab,
                        motorCarrier,
                    }}
                >
                    <Paper className="w-full max-w-[1600px] rounded-lg" elevation={6}>
                        <Navigation />

                        <Panel panelIndex={0} activeTabIndex={activeTab}>
                            <Summary />
                            <Footer />
                        </Panel>

                        <Panel panelIndex={1} activeTabIndex={activeTab}>
                            <Insights />
                            <Footer gray />
                        </Panel>

                        <Panel panelIndex={2} activeTabIndex={activeTab}>
                            <Crashes />
                            <Footer />
                        </Panel>

                        <Panel panelIndex={3} activeTabIndex={activeTab}>
                            <Inspections />
                            <Footer />
                        </Panel>

                        <Panel panelIndex={4} activeTabIndex={activeTab}>
                            <Violations />
                            <Footer />
                        </Panel>
                    </Paper>
                </Context.Provider>
            )}
        </div>
    );
}
