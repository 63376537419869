/* eslint-disable react-hooks/rules-of-hooks */
import { useAuth0 } from "@auth0/auth0-react";
import { OrganizationInvitation, Permission, User } from "@deathstar/types/waypoint";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import QueryString from "qs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../util/queryClient";
import { blazar } from "../util/blazar";
import { NotFoundError, ResponseError, UnauthenticatedError } from "../util/exceptions";

const userApi = {
    queryKeys: {
        me: () => ["user"],
    },
    useMe<T = User & { invitations: OrganizationInvitation[] }>(
        options?: Partial<UseQueryOptions<User & { invitations: OrganizationInvitation[] }, Error | UnauthenticatedError, T>>
    ) {
        const { user, isAuthenticated } = useAuth0();
        const navigate = useNavigate();

        const query = useQuery<User & { invitations: OrganizationInvitation[] }, Error | UnauthenticatedError, T>({
            queryKey: userApi.queryKeys.me(),
            queryFn: async () => {
                if (user) {
                    const me = await blazar.fetchJson<User & { invitations: OrganizationInvitation[] }>(`waypoint/users/me`);
                    if (me) {
                        return me;
                    } else {
                        throw new NotFoundError();
                    }
                } else {
                    throw new UnauthenticatedError();
                }
            },
            staleTime: 5000,
            retry: false,
            ...options,
            enabled: isAuthenticated,
        });
        const error = query.error;
        useEffect(() => {
            if (error instanceof ResponseError && error.status === 404 && !window.location.pathname.startsWith("/member-setup")) {
                navigate({
                    pathname: "/member-setup",
                    search: QueryString.stringify({ redirect: window.location.pathname }),
                });
            }
        }, [error, navigate]);

        return query;
    },

    async hasPermission(accountId: string, permissionId: Permission["id"]): Promise<boolean> {
        const { granted } = await blazar.fetchJson<{ userId: string; accountId: string; permissionId: Permission["id"]; granted: boolean }>(
            `waypoint/users/me/orgs/${accountId}/permissions/${permissionId}`
        );
        return granted;
    },

    useHasPermission(accountId: string, permissionId: Permission["id"], config?: Partial<UseQueryOptions<boolean, ResponseError>>) {
        return useQuery<boolean, ResponseError>({
            queryKey: ["user", "has-permission", accountId, permissionId],
            queryFn: () => userApi.hasPermission(accountId, permissionId),
            ...config,
        });
    },

    async update(updates: Partial<Pick<User, "displayName" | "lastVisitedAccountId">>) {
        await blazar.fetch(`waypoint/users/me`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updates),
        });
    },

    async joinTestOrg() {
        await blazar.fetch(`waypoint/users/me/join-test-org`, {
            method: "POST",
        });
    },

    invalidateQueries() {
        queryClient.invalidateQueries({ queryKey: ["user"] });
    },
};

export const user = userApi;
