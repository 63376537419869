import { CoverageOption, Policy } from "@deathstar/types/northstar";
import { ActionButton, classNames } from "@deathstar/ui";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { orderBy } from "lodash";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../../api/api";
import { useAccountId } from "../../../api/useAccountId";
import { Dialog } from "../../../components/dialog/Dialog";
import Loader from "../../../components/loader/Loader";
import MonthlyReportView from "./MonthlyReportView";

export default function MonthlyReportSelector() {
    const accountId = useAccountId();
    const { policyId, year: yearStr, month: monthStr } = useParams();
    const { data, isLoading } = api.monthlyReports.useFind(accountId!);

    // because I can't figure out how to get the base path of this route...
    const backs =
        `./` +
        [!!policyId, !!yearStr, !!monthStr]
            .filter(Boolean)
            .map(() => "../")
            .join("");

    // make sure our Year and Month params are actually numbers. Just doing a parseInt would mean strings like
    // 11111abc would be valid (because javascript...), so we need to check that the stringified number is the same as the original string
    const month = monthStr ? (String(parseInt(monthStr, 10)) === monthStr ? parseInt(monthStr, 10) : 0) : 0;
    const year = yearStr ? (String(parseInt(yearStr, 10)) === yearStr ? parseInt(yearStr, 10) : 0) : 0;

    const navigate = useNavigate();

    const create = api.monthlyReports.useCreate(accountId!, policyId!);

    useEffect(() => {
        if ((yearStr && !year) || (monthStr && !month)) {
            navigate(`${backs}${policyId}`);
        }
    }, [backs, month, monthStr, navigate, policyId, year, yearStr]);

    const sorted = useMemo(() => {
        return orderBy(data || [], [(x) => x.hasReportDue, (x) => x.effectiveDate], ["desc", "desc"]);
    }, [data]);

    if (isLoading) {
        return <Loader />;
    }

    const selected = data?.find((d) => d.policyId === policyId);

    return (
        <div className="mx-2 my-8 flex h-full flex-col gap-12 overflow-hidden md:mx-8">
            <div>
                <p className="text-xl">Monthly Reporting</p>
                <p className="text-sm font-light text-stone-600">
                    View, manage, and submit your monthly reports for your policies. Select a policy to get started.
                </p>
            </div>
            {data?.length ? (
                <div className="flex grow gap-4 overflow-hidden">
                    <div
                        className={classNames(
                            "flex w-full shrink-0 grow flex-col gap-4 overflow-hidden lg:w-[min(40%,600px)] lg:grow-0",
                            !!selected && "hidden lg:flex"
                        )}
                    >
                        <p className="font-light text-waypoint-blue">Your reporting policies</p>
                        <ul className={classNames("grow overflow-y-auto rounded border p-4")}>
                            {sorted.map((r) => (
                                <li key={r.policyId}>
                                    <Link
                                        to={`${backs}${r.policyId}`}
                                        className={classNames(
                                            "block rounded p-4",
                                            policyId === r.policyId ? "bg-waypoint-blue text-white" : "hover:bg-blue-50",
                                            policyId !== r.policyId &&
                                                r.policyStatus !== Policy.Status.ACTIVE &&
                                                r.policyStatus !== Policy.Status.PRE_CANCELLATION &&
                                                "text-stone-600"
                                        )}
                                    >
                                        <div className="flex flex-col justify-between xl:flex-row xl:items-end xl:gap-4">
                                            <p
                                                className={classNames(
                                                    "overflow-hidden text-ellipsis whitespace-nowrap",
                                                    r.policyStatus === Policy.Status.ACTIVE ||
                                                        r.policyStatus === Policy.Status.PRE_CANCELLATION
                                                        ? "font-medium"
                                                        : "font-light"
                                                )}
                                            >
                                                {r.writingCompanyName}
                                            </p>
                                            {r.hasReportDue && (
                                                <p
                                                    className={classNames(
                                                        "mb-1 rounded-full px-2 pb-0.5 leading-none small-caps",
                                                        policyId === r.policyId ? "bg-white/20" : "bg-sky-100 text-blue-700"
                                                    )}
                                                >
                                                    Report due
                                                </p>
                                            )}
                                        </div>
                                        <p className="whitespace-nowrap text-sm font-light">
                                            {moment(r.effectiveDate).format("MM/DD/YY")} - {moment(r.expirationDate).format("MM/DD/YY")}
                                        </p>
                                        <p
                                            className={classNames(
                                                "line-clamp-2 text-sm",
                                                policyId === r.policyId ? "text-white/80" : "text-stone-500"
                                            )}
                                        >
                                            {r.covereageOptionIds.map((c) => CoverageOption.getMetadata(c).name).join(", ")}
                                        </p>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={classNames("hidden shrink-0 grow flex-col overflow-hidden lg:mt-10 lg:flex", !!selected && "!flex")}>
                        <Link to="./.." className="mb-2 block w-max lg:hidden">
                            <ActionButton>Back to policies</ActionButton>
                        </Link>
                        <ul className="grow overflow-y-auto rounded border p-4">
                            {selected ? (
                                selected.availableMonths.length ? (
                                    selected.availableMonths.map(({ month, year }) => {
                                        const report = selected.reports.find((report) => report.year === year && report.month === month);
                                        return (
                                            <li key={`${year}-${month}`} className="group">
                                                <button
                                                    onClick={() => {
                                                        if (report) {
                                                            navigate(`${year}/${month}`);
                                                        } else {
                                                            create.mutate(
                                                                { year, month },
                                                                { onSuccess: () => navigate(`${year}/${month}`) }
                                                            );
                                                        }
                                                    }}
                                                    disabled={create.isPending}
                                                    className={classNames(
                                                        "group flex w-full items-center gap-2 border-b py-2 pl-2 pr-4 text-left hover:bg-stone-50 sm:rounded sm:border-b-0"
                                                    )}
                                                >
                                                    <div className="flex grow flex-col gap-2 sm:flex-row sm:items-center">
                                                        <div className="w-40 shrink-0">
                                                            {report ? (
                                                                report.isFinalized ? (
                                                                    <span className="w-max rounded-full bg-emerald-50 px-2 text-sm text-emerald-600 small-caps">
                                                                        Completed
                                                                    </span>
                                                                ) : report.isSubmitted ? (
                                                                    <span className="w-max rounded-full bg-blue-50 px-2 text-sm text-blue-600 small-caps">
                                                                        Submitted
                                                                    </span>
                                                                ) : (
                                                                    <span className="w-max rounded-full bg-orange-50 px-2 text-sm text-orange-600 small-caps">
                                                                        Awaiting your review
                                                                    </span>
                                                                )
                                                            ) : (
                                                                <span className="w-max rounded-full bg-orange-50 px-2 text-sm text-orange-600 small-caps">
                                                                    Awaiting your review
                                                                </span>
                                                            )}
                                                        </div>
                                                        <p className="grow">
                                                            {moment()
                                                                .year(year)
                                                                .month(month - 1)
                                                                .format("MMMM YYYY")}
                                                        </p>
                                                    </div>
                                                    <div className="relative w-12 shrink-0">
                                                        <ChevronRightIcon className="absolute right-0 top-1/2 h-4 w-4 -translate-y-1/2 text-stone-500 opacity-100 transition-opacity group-hover:opacity-0" />
                                                        <div className="h-full w-full rounded bg-blue-600 p-1 text-center text-sm text-white opacity-0 shadow-sm transition-opacity hover:bg-blue-700 group-hover:opacity-100">
                                                            View
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <li className="py-2 pl-2 pr-4">
                                        <p className="text-center text-stone-500">There are no reports for this policy, yet</p>
                                    </li>
                                )
                            ) : (
                                <li className="py-2 pl-2 pr-4">
                                    <p className="text-center text-stone-500">Select a policy on the left to view your reports</p>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            ) : (
                <div className="flex h-full items-center justify-center">
                    <div className="rounded border p-8">
                        <p className="text-center text-stone-500">No reportable policies found</p>
                    </div>
                </div>
            )}
            <Dialog
                open={!!month && !!year}
                onClose={() => {
                    navigate("./../../..");
                }}
                className="h-full w-full md:h-3/4"
                style={{
                    maxWidth: "min(calc(100vw - 64px), 1064px)",
                }}
            >
                <MonthlyReportView />
            </Dialog>
        </div>
    );
}
