import { AppState, Auth0Context, Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import "@deathstar/ui/richTextEditor/richTextEditor.css";
import * as Sentry from "@sentry/react";
import { StrictMode, useCallback, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, useNavigate } from "react-router-dom";
import "reflect-metadata";
import { auth0 } from "./api/util/auth0TokenExposer";
import { App } from "./app";
import environment from "./environment";
import "./index.css";
import { queryClient } from "./util/queryClient";

/** Do not export any variables or methods from this file, since HMR will reload this file and things will break.
 * `Sentry.init` should only be called once and will throw a fit. Same with `createRoot` and `root.render(...)`.
 */

Sentry.init(environment.sentry);

/**
 * Needed for maintaining location when using react-router. Without this, reloading a nested page like `/sol/foo/bar` would
 * always bring you back to the path set by the `redirect_uri` field instead of back to `/sol/foo/bar`.
 * https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#protecting-a-route-in-a-react-router-dom-v6-app
 */
const Auth0ProviderWithRedirectCallback = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();
    const onRedirectCallback = useCallback(
        (appState: AppState | undefined) => {
            navigate((appState && appState.returnTo) || window.location.pathname);
        },
        [navigate]
    );
    return (
        <Auth0Provider
            clientId={environment.auth0.clientId}
            domain={environment.auth0.domain}
            authorizationParams={{ redirect_uri: window.location.origin + "/sol", audience: environment.auth0.audience }}
            onRedirectCallback={onRedirectCallback}
        >
            <Auth0Context.Consumer>
                {/* A hack to get the access token outside of React Context.
                 * See https://gist.github.com/adamjmcgrath/0ed6a04047aad16506ca24d85f1b2a5c
                 */}
                {({ getAccessTokenSilently }) => {
                    auth0.deferred.resolve(getAccessTokenSilently);
                    return children;
                }}
            </Auth0Context.Consumer>
        </Auth0Provider>
    );
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <StrictMode>
        <BrowserRouter>
            <Auth0ProviderWithRedirectCallback>
                <SetSentryUser />
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </Auth0ProviderWithRedirectCallback>
        </BrowserRouter>
    </StrictMode>
);

function SetSentryUser() {
    const { user } = useAuth0();
    const hasSetUser = useRef(false);
    useEffect(() => {
        if (user && !hasSetUser.current) {
            hasSetUser.current = true;
            Sentry.setUser({
                id: user.sub,
                email: user.email,
            });
        }
    });
    return null;
}
